/* @font-face {
	font-family: 'HankenSans';
	src:
		local('HankenSans Thin Italic'),
		local('HankenSans-ThinItalic'),
		url('HankenSans-ThinItalic.woff2') format('woff2'),
		url('HankenSans-ThinItalic.woff') format('woff');
	font-weight: 100;
	font-style: italic;
} */

@font-face {
  font-family: 'HankenSans';
  src:
    local('HankenSans Light'),
    local('HankenSans-Light'),
    url('HankenSans-Light.woff2') format('woff2'),
    url('HankenSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'HankenSans';
  src:
    local('HankenSans Bold Italic'),
    local('HankenSans-BoldItalic'),
    url('HankenSans-BoldItalic.woff2') format('woff2'),
    url('HankenSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'HankenSans';
  src:
    local('HankenSans Italic'),
    local('HankenSans-Italic'),
    url('HankenSans-Italic.woff2') format('woff2'),
    url('HankenSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'HankenSans';
  src:
    local('HankenSans Med Italic'),
    local('HankenSans-MediumItalic'),
    url('HankenSans-MediumItalic.woff') format('woff'),
    url('HankenSans-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'HankenSans';
  src:
    local('HankenSans Bold'),
    local('HankenSans-Bold'),
    url('HankenSans-Bold.woff') format('woff'),
    url('HankenSans-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'HankenSans';
  src:
    local('HankenSans ExtraBoldItalic'),
    local('HankenSans-ExtraBoldItalic'),
    url('HankenSans-ExtraBoldItalic.woff') format('woff'),
    url('HankenSans-ExtraBoldItalic.woff2') format('woff2');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'HankenSans';
  src:
    local('HankenSans Regular'),
    local('HankenSans-Regular'),
    url('HankenSans-Regular.woff') format('woff'),
    url('HankenSans-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HankenSans';
  src:
    local('HankenSans Light Italic'),
    local('HankenSans-LightItalic'),
    url('HankenSans-LightItalic.woff') format('woff'),
    url('HankenSans-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'HankenSans';
  src:
    local('HankenSans ExtBd'),
    local('HankenSans-ExtraBold'),
    url('HankenSans-ExtraBold.woff') format('woff'),
    url('HankenSans-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'HankenSans';
  src:
    local('HankenSans Medium'),
    local('HankenSans-Medium'),
    url('HankenSans-Medium.woff2') format('woff2'),
    url('HankenSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'HankenSans';
  src:
    local('HankenSans Black Italic'),
    local('HankenSans-BlackItalic'),
    url('HankenSans-BlackItalic.woff2') format('woff2'),
    url('HankenSans-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'HankenSans';
  src:
    local('HankenSans Black'),
    local('HankenSans-Black'),
    url('HankenSans-SemiBold.woff2') format('woff2'),
    url('HankenSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'HankenSans';
  src:
    local('HankenSans Black Italic'),
    local('HankenSans-BlackItalic'),
    url('HankenSans-SemiBoldItalic.woff2') format('woff2'),
    url('HankenSans-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'HankenSans';
  src:
    local('HankenSans Black'),
    local('HankenSans-Black'),
    url('HankenSans-Black.woff2') format('woff2'),
    url('HankenSans-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
