/* RESETS */

body {
	font-family: 'HankenSans', system-ui, 'Arial', sans-serif;
	font-display: swap;
}

html,
body {
	margin: 0;
	padding: 0;
	border: 0;
	box-sizing: border-box;
}

div,
header,
main,
footer,
input,
select,
iframe,
button,
textarea {
	box-sizing: border-box;
}

h1 {
	pointer-events: none;
}

h1,
h2,
h3 {
	font-weight: normal;
}

h1,
h2,
h3,
p {
	line-height: 1.5em;
}

p {
	margin-bottom: 10px;
}

a {
	color: var(--cf-black);
	text-decoration: none;
}

/* VARIABLES */

@media (max-width: 750px) {
	.mobile-hidden {
		display: none !important;
	}
}

@media (max-width: 480px) {
	.tiny-hidden {
		display: none !important;
	}
}

@media (min-width: 750px) {
	.desktop-hidden {
		display: none !important;
	}
}

.middle {
	height: 100%;
}

.mobile-compatible-snackbar {
	white-space: normal !important;
}
